*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

nav {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.navlinks {
    width: 50%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    list-style: none;
}